import React from "react"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"
import SEO from "layout/SEO"

const PrivacyPolicy = () => {
  return (
    <Container isCentered>
      <SEO title="Privacy Policy" />
      <h2>Privacy Policy</h2>
      <hr />
      <div className="content is-medium">
        <div className="message">
          <div className="message-body">
            You should carefully read the entire following privacy statement
            before creating your account and/or availing our products or
            services. This agreement contains important terms that affect your
            legal rights. By using our site or service, you hereby agree to the
            terms of this privacy statement policy.
          </div>
        </div>
        <p>
          MedGrocer respects the privacy of its online visitors and users of its
          products and services and complies with Republic Act No. 10173 also
          known as the Data Privacy Act of 2012...
        </p>

        <Collapsible title="We Are Responsible" isOpen>
          <p>
            MedGrocer values your personal information. We will maintain and
            protect all personal information you entrust with us. All
            information we collect will be safely and securely stored.
          </p>
        </Collapsible>

        <Collapsible title="What We Collect">
          <p>
            MedGrocer collects information you provide when you interact with
            our services such as:
          </p>
          <ul>
            <li>
              Contacting us regarding inquiries or quotations about our services
            </li>
            <li>Booking a teleconsultation</li>
            <li>Accomplishing forms or questionnaires</li>
            <li>Using interactive sections of our services</li>
            <li>Registering a MedGrocer account on our website</li>
          </ul>
        </Collapsible>

        <Collapsible title="Personally Identifiable Information and How It Will Be Used">
          <p>
            “Personally identifiable information” is information that can be
            used to uniquely identify a user such as name, age, civil status,
            gender, mailing address, email address, telephone number, mobile
            number, and other personal details...
          </p>
        </Collapsible>

        <Collapsible title="How Your Information May Be Shared">
          <p>MedGrocer may share your information in the following ways:</p>
          <ul>
            <li>
              <strong>Other Organizations:</strong> If you are a member of an
              organization with a previously agreed upon Privacy Policy...
            </li>
            <li>
              <strong>Other Services:</strong> MedGrocer may allow third parties
              performing services under contract...
            </li>
            <li>
              <strong>Clinical Service Providers:</strong> We may share your
              information with other healthcare services...
            </li>
            <li>
              <strong>Legal Purposes:</strong> MedGrocer may release personally
              identifiable information to comply with court orders...
            </li>
          </ul>
        </Collapsible>

        <Collapsible title="External Websites and Companies">
          <p>
            Other websites and companies that link to and from MedGrocer's
            online sites and products may collect personally identifiable
            information...
          </p>
        </Collapsible>

        <Collapsible title="Children Under the Age of 18">
          <p>
            MedGrocer will not knowingly collect personally identifiable
            information from any person under the age of 18...
          </p>
        </Collapsible>

        <Collapsible title="Cookies and Other Information on a User’s Machine">
          <p>
            MedGrocer’s products, services and sites may employ cookies and
            other technologies such as pixel tags...
          </p>
        </Collapsible>

        <Collapsible title="Choice/Opt-out">
          <p>
            MedGrocer gives users the option to receive promotional email
            communications from MedGrocer...
          </p>
        </Collapsible>

        <Collapsible title="Storage and Security of Personally Identifiable Information">
          <p>
            Personally identifiable information provided to MedGrocer will be
            collected, processed, and stored by MedGrocer in the cloud...
          </p>
        </Collapsible>

        <Collapsible title="Corrections, Updates, and Removal of Personally Identifiable Information">
          <p>
            If you are concerned about the accuracy of personally identifiable
            information maintained by MedGrocer...
          </p>
        </Collapsible>

        <Collapsible title="Contact MedGrocer’s Data Privacy Officer">
          <p>
            Our Data Privacy Officer provides support regarding data
            privacy-related questions...
          </p>
          <ul>
            <li>
              <strong>Mail:</strong> MG Health Solutions, Inc. (MedGrocer), 24F
              Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City
            </li>
            <li>
              <strong>Call:</strong> +632 8541 4868
            </li>
            <li>
              <strong>Email:</strong>{" "}
              <a href="mailto:dpo@medgrocer.com">dpo@medgrocer.com</a>
            </li>
          </ul>
        </Collapsible>
      </div>
    </Container>
  )
}

export default PrivacyPolicy
